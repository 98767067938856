import React from 'react';

interface ModalProps {
  isOpen: boolean;
  close: () => void;
  content: JSX.Element | string;
}

const Modal: React.FC<ModalProps> = ({ isOpen, close, content }) => {
  if (!isOpen) return null;

  return (
    <div style={styles.modalBackground} onClick={close}>
      <div style={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        {content}
        <button style={styles.closeButton} onClick={close}>Close</button>
      </div>
    </div>
  );
};

// Use React.CSSProperties for correct typing

const styles: { [key: string]: React.CSSProperties } = {
    modalBackground: {
      position: 'fixed',
      top: 0,
      left: '50%', // Center horizontally relative to the viewport
      transform: 'translateX(-50%)', // Shift left by half its width to center
      width: '100%', // Cover the full width of the viewport
      height: '100%', // Cover the full height of the viewport
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center', // This centers the modalContent horizontally
      alignItems: 'center', // This centers the modalContent vertically
      zIndex: 1000,
    },
    modalContent: {
      maxWidth: '800px', // Set the max width of the modal content
      width: '100%', // Ensure it takes up to 800px width
      margin: '0 auto', // This is optional based on your layout needs
      backgroundColor: '#fff',
      padding: '20px',
      borderRadius: '5px',
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      zIndex: 1001,
    },
    closeButton: {
      marginTop: '10px',
      alignSelf: 'flex-end',
      padding: '5px 10px',
      background: 'red',
      color: 'white',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
    }
  };
  

export default Modal;
